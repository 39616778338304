import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/src/layout/footer/footer.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/(content-info)/content-info-layout.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/layout/header/header.tsx");
